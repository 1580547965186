/*
Debug Helper
*/
body {
  &.debug {
    margin-top: 20px;

    &::before {
      content: 'default';
      position: fixed;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 25px;
      border: 4px solid #fff;
      border-left: 25vw solid #fff;
      border-right: 25vw solid #fff;
      box-sizing: border-box;
      z-index: 1000;
      background: $color-accent;
      color: $color-light;
      text-align: center;
      font-family: monospace;

      @include x-big-desktop {
        content: '$x-big-desktop';
        background: red;
      }
      @include big-desktop {
        content: '$big-desktop';
        background: teal;
      }
      @include desktop {
        content: '$desktop';
        background: blue;
      }
      @include big-tablet {
        content: '$big-tablet';
        background: purple;
      }
      @include tablet {
        content: '$tablet';
        background: orange;
      }
      @include big-mobile {
        content: '$big-mobile';
        background: tomato;
      }
      @include mobile {
        content: '$mobile';
        background: green;
      }
    }
  }
}