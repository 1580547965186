/*
Common
Move common component to its own file when needed.
*/


html,body {
  color: $color-dark;
}


body.lock {
  overflow: hidden;
}

body {
  background: $color-light-gray;
}

a {
  @extend %hover-transition;
}

br {
  @include big-mobile {
    display: none;
  }
}


// Inquiry bar
.inquiry-bar {
  @include contentWidth(padding);
  padding: $space-md;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  @include big-mobile {
    flex-direction: column;

    .btn {
      @include paddingY(.4rem);
    }
  }

  & > * {
    @include marginX($space-md);

    @include big-mobile {
      margin-bottom: $space-md;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  span {
    font-size: px-to-rem(18);
  }
}

// Move later if needed
.specs-bar {
  background: $white;
  padding: $space-md;
  display: flex;

  li {
    display: flex;
    margin-right: $space-lg;
    align-items: flex-end;

    & > * {
      margin-right: $space-md;
    }

    img {
      width: 6vw;
      max-width: 30px;
      filter: brightness(0);
    }
  }
}





.currency {
  position: relative;
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  @extend %box-shadow;
  border-radius: $border-radius;
  background: $color-light-gray;

  &::before {
    content: '$';
    left: 0;
    padding: 0 $space-md;
    font-weight: bold;
    font-size: 1.2rem;
  }

  input[type="text"],
  input[type="number"] {
    border-radius: 0;
    background: $white !important;
    margin-bottom: 0 !important;
    padding-left: $space-md * 2 !important;
    border-top-left-radius: 0 !important;;
    border-bottom-left-radius: 0 !important;;
  }
}

// thank you
.thank-you {
  @include marginX(auto);
  max-width: 400px;
  border-radius: $border-radius;
  background: $white;
  padding: $space-lg;
  text-align: center;
}