/*Hero*/

.hero {
  height: 90vh;
  position: relative;
  background-position: top;
  background-size: cover;

  @include big-mobile {
    height: 112vh;
  }

  &::after {
    content: '';
    background: $color-accent;
    opacity: .8;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .hero__content-wrapper {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width :60%;
    max-width: $big-tablet;

    @include tablet {
      padding: $space-md;
      width: 100%;
    }

    & > h2 {
      display: none;

       @include tablet {
         display: block;
         color: $white;
         position: relative;
         z-index: 4;
         text-align: center;
         padding: $space-md;
       }
     }
  }

  .hero__text {
    background: $white;
    padding: $space-lg * 2;
    padding-bottom: $space-sm / 2;
    border-radius: $border-radius;

    @include big-mobile {
      padding: $space-md * 2;
    }

    &::before {
      content: '';
      background: url('/img/circle-check.svg');
      display: block;
      width: 60px;
      height: 60px;
      background-size: contain;
      background-repeat: no-repeat;
      position: relative;
      margin-top: -75px;
      left: 50%;
      transform: translateX(-50%);

      @include big-mobile {
        margin-top: -50px;
        width: 40px;
        height: 40px;
      }
    }

    &::after {
        content: '';
        background: url('/img/blob.svg');
        display: block;
        width: 142px;
        height: 120px;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        bottom: 0;
        right: 0;

        @include tablet {
          bottom: 15px;
          right: 15px;
        }
    }


    h2 {
      color: $color-accent;
      @include rfs(32);

      @include tablet {
        &:first-of-type {
          display: none;
        }
      }
    }

    em {
      display: block;
      color: $color-dark;
      @include rfs(18);
      font-weight: normal;
      margin-top: $space-md;
    }

    ul {
      @include marginX($space-lg * 2);
      @include marginY($space-lg);
      align-items: center;

      @include big-mobile {
        @include marginX(0);
      }



      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        @include marginY($space-lg);

        @include tablet {
          width: calc(100% / 3);
        }
      }

      img {
        height: 100%;
        width: 100%;
      }

      figure {
        height: 50px;
        width: 50px;
        margin-bottom: $space-md;

        @include big-mobile {
          height: 40px;
          width: 40px;
        }
      }

      p {
        color: $color-accent;
        font-weight: bold;

        @include big-mobile {
          font-size: .6rem;
        }
      }

    }

    .btn {
      min-width: 220px;
      @include paddingY(.65rem);
      @include marginX($space-md);
      margin-bottom: $space-md;

      @include big-mobile {
        min-width: 150px;
        max-width: 220px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

  }
}
