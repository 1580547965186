/*
Google Map
*/

.map {
  height: 60vh;
  position: relative;
  overflow: hidden;

  @include big-mobile {
    @include marginX($space-md);
  }
}