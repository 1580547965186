.slider {
  margin-bottom: 0;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  overflow: hidden;
  width: 100%;
  // height: 80vh;

  @include big-mobile {
    // height: 60vh;
  }
}

.slider-wrapper {
  position: relative;
  margin-bottom: 0;
}

.slider-control-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background: $white;
  width: 50px;
  height: 50px;
  padding: $space-sm;
  cursor: pointer;
  z-index: 10;

  &:after {
    content: '';
    background-image: url('/img/icon-arrow.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50%;
    display: block;
    width: 100%;
    height: 100%;
  }

  &.slider-control-btn--prev {
    left: 0;
    &:after {
      transform: rotate(180deg);
    }
  }

  &.slider-control-btn--next {
    right: 0;
  }
}