/*
Section Component
*/

/*
========================
Default
========================
*/
.section {
  @include paddingY($space-lg * 2);
  @include contentWidth(padding);
  margin-bottom: $space-lg * 2;

  &:last-child {
    margin-bottom: 0;
  }

  @include tablet {
    margin-bottom: $space-md;
  }


  & > * {
    margin-bottom: $space-lg;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.section__title {
  color: $color-accent;
  margin-bottom: $space-lg * 2;

  @include big-mobile {
    margin-bottom: $space-lg;
  }
}

.section {
  .btn {
    @include marginX(auto);
    display: block;
    max-width: 220px;
  }
}


/*
========================
Variations
========================
*/

.section--light {
  background: $white;
}

.section--light--card {
  background: $white;
  padding: $space-lg;
  border-radius: $border-radius;
  @include contentWidth(margin);
  @include paddingX($space-md * 4);
  margin-bottom: $space-lg * 4;

  @include big-mobile {
    @include paddingX($space-md);
  }
}

.section--testimonial {

  margin-top: -100px;
  position: relative;
  z-index: 2;

  @include desktop {
    margin-top: -20px;
  }

  @include big-mobile {
    display: none;
  }


  ul {
    @extend %box-shadow;
    background: $white;
    // border-radius: $border-radius;
    border-radius: 20px;
    flex-wrap: nowrap;
    display: flex;
    padding: $space-lg * 2 $space-lg * 4;

    @include big-desktop {
      @include paddingX($space-lg * 2);
    }

    & > * {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: $space-lg * 3;
      position: relative;
      width: calc((100% / 3) - #{$space-md});
      @include paddingX($space-lg * 2);

      @include big-desktop {
        margin-left: $space-lg;
      }

      @include big-tablet {
        width: 100%;
      }


      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -40px;
        background: url('/img/quote.svg');
        background-repeat: no-repeat;
        background-size: contain;
        width: 50px;
        height: 50px;
        display: block;

        @include big-desktop {
          left: -30px;
        }

        @include big-tablet {
          top: 10px;
          width: 30px;
          height: 30px;
        }
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    @include big-tablet {
      flex-direction: column;

      & > * {
        width: 100%;
        @include marginX(0);
        margin-bottom: $space-lg * 4;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }



    p {
      display: flex;
      flex-direction: column;
      font-style: italic;
      @include rfs(16);

      &:first-child {
        margin-bottom: $space-lg;

        @include tablet {
          margin-bottom: $space-md;
        }
      }
    }
  }

  .name {
    font-weight: bold;
  }

}

.section--testimonial-mobile__nav {
  display: flex;
  justify-content: center;

  li {
    width: 12px;
    height: 12px;
    margin: $space-md;
    display: block;
    // border: 2px solid $color-light-gray;
    border-radius: 50%;
    background: $color-gray;
    -webkit-box-shadow: 0px 0px 26px 0px rgba($color-dark, 1);
    -moz-box-shadow: 0px 0px 26px 0px rgba($color-dark, 1);
    box-shadow: 0px 0px 26px 0px rgba($color-dark, 1);


  }
}

.section--testimonial-mobile {
  display: none;
  @include big-mobile {
    background-color: $white;
    padding: $space-md * 2;
    display: block;

    .slides {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        background: url('/img/quote.svg');
        background-repeat: no-repeat;
        background-size: contain;
        width: 50px;
        height: 50px;
        display: block;
        opacity: .2;


        @include big-tablet {
          top: 10px;
          width: 100px;
          height: 100px;
        }
      }
    }

    p {
      display: flex;
      flex-direction: column;
      font-style: italic;
      @include rfs(16);

      &:first-child {
        margin-bottom: $space-lg;

        @include tablet {
          margin-bottom: $space-md;
        }
      }
    }

    .name {
      font-weight: bold;
    }
  }

  @for $idx from 0 through 2 {
    [data-current="#{$idx}"] {
      [data-index="#{$idx}"] {
        background: $white;
        border: 1px solid $color-light-gray;
      }
    }
  }

}


// Section USP
.section--usp {
  background: $white;
  @include widthLimitAt(padding, 860px);
}

// Section listing header
.section--listing-header {
  // @include paddingY(0);
  padding-bottom: 0;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;

  @include tablet {
    flex-direction: column;
    margin-bottom: $space-lg;

    nav {
      ul {
        display:  flex;
        & > * {
          width: 100px;
          margin: 0;
          margin-right: $space-lg;
        }
      }
    }

    .section__title {
      margin-bottom: $space-md;
    }
  }
}

.section--listing {
  padding-top: 0;
}

.section--form {
  margin-top: -20vw;
  position: relative;
  z-index: 2;

  @include x-big-desktop {
    margin-top: -25vw;
  }

  @include tablet {
    margin-top: -15vw;
  }

  @media (min-width: $x-big-desktop) {
    margin-top: -15vw;
  }

  // @include big-mobile {
  //   margin-top: 0;
  // }

  form {
    @extend %box-shadow;
    width: 70%;

    @include tablet {
      width: 100%;
    }

  }

  @include tablet {
    width: 100%;
  }
}

// Section Slider
.section--slider {
  margin-bottom: 0;
  padding-bottom: 0;
}

// Section lising single
.section--single {
  .section__title {
    @extend %h2;
    padding-bottom: $space-md;
    border-bottom: 2px solid $color-accent;
    color: $color-accent;

    small {
      color: $color-accent;
      @extend %h4;
    }
  }

  .section--single__list {

    li {
      @extend .list-item;
      line-height: 1.2;
      margin-bottom: $space-md;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

// move later
#single_form {
  padding: 0;
  height: 0;
  max-height: 0;
  overflow: hidden;
  transition: .3s ease-in-out;
  // @include marginX(auto);
  @include widthLimitAt(padding, 800px);

  &.is-opened {
    height: auto;
    max-height: 1000px;
  }
}

// Section Post
// TODO: too much important override for content area, refactor when is possible
.section--post {
  background: $white;
  @include widthLimitAt(padding, 960px);

  [title="get-start"] {
    @extend .btn;
    background: $color-accent !important;
    border: 2px solid $color-accent !important;

    &:hover,
    &:active {
      border-color: darken($color-accent, 10%) !important;
      background: darken($color-accent, 10%) !important;
      color: $color-light !important;
    }

    position: relative;
    padding-right: 40px !important;

    &::after {
      content: '';
      background-image: url('/img/circle-check-2.svg');
      width: 30px;
      height: 30px;
      display: inline-block;
      background-repeat: no-repeat;
      position: absolute;
      margin-left: 10px;
    }
  }

  p + h3 {
    margin-top: $space-md * 4;
  }
}