/*
Navigation
*/

.main-nav {
  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;

    li {
      margin-right: $space-lg;

      &:last-child {
        margin-right: 0;
      }
    }

    .btn {
      @include paddingY(.4rem);
    }

    @include big-tablet {
      // display: none;
      position: fixed;
      transform: translateX(4000px) skewX(-70deg);
      transition: .4s ease-in-out;
      width: 100%;
      height: 100%;
      left: 0;
      top: 68px;
      z-index: 100;
      background: $color-accent;
      flex-direction: column;
      justify-content: center;
      display: flex;

      li {
        margin-bottom: $space-lg * 1.2;

        &:nth-last-child(2) {
          margin-bottom: $space-lg * 4;
        }
      }

      a {
        color: $white;
        font-weight: bold;
      }

      .btn {
        border-color: $white;
      }

      &.is-opened {
        transform: translateX(0);
      }
    }
  }

  a {
    color: $color-dark;

    &:hover {
      color: $color-accent;

      @include big-tablet {
        color: $white;
      }
    }
  }
}

.btn-nav {
  display: none;

  & > * {
    @extend %hover-transition;
  }

  @include big-tablet {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: $color-light;
    width: 46px;
    height: 46px;

    position: absolute;
    z-index: 100;
    right: $space-md;
    top: 12px;

    & > * {
      width: 80%;
      height: 3px;
      background: $color-dark;
      display: block;
      @include marginX(auto);

      &:nth-child(2) {
        @include marginY($space-sm);
      }
    }

    &.active {
      & > * {

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(1) {
          transform: rotate(45deg);
          position: relative;
          top: 12px;
        }

        &:nth-child(3) {
          transform: rotate(135deg);
          top: -10px;
          position: relative;
        }
      }
    }
  }
}


.view-switcher {
  & > ul {
    display: flex;

    & > * {
      margin-left: $space-md;
    }
  }

  .btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 110px;
  }

  .btn--primary {
    .icon {
      filter: brightness(5);
    }
  }

  .btn--secondary {
    &:hover {
      .icon {
        filter: brightness(5);
      }
    }
  }

  .icon {

    &::after {
      content: '';
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      display: block;
      width: 20px;
      height:20px;
    }


    &.icon--map::after {
      background-image: url('/img/icon-map-view.svg');
    }
    &.icon--grid::after {
      background-image: url('/img/icon-grid-view.svg');
    }
  }
}
