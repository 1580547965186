/*
Header
*/

.site-header {
  @include contentWidth(padding);
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: $color-light;
  align-items: center;
  @include paddingY($space-md);

  @include big-desktop {
    padding-left: 5vw;
    padding-right: 5vw;
  }

  .main-nav {
    width: 60%;

    @include big-desktop {
      width: 80%;
    }

  }
}

.site-header__logo {
  width: 120px;
}


