// Section media object

$offset-value: $space-md * 4;
.media-object {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $space-lg * 2;
  // position: relative;

  & > * {
    width: calc(100% / 2);

    @include big-tablet {
      width: 100%;
    }
  }

  @include big-desktop {
    .media-object__img {
      width: 45%;

      @include big-tablet {
        width: 100%;
      }
    }

    .media-object__text {
      width: 60%;

      @include big-tablet {
        width: 100%;
      }
    }
  }

  .media-object__text {
    li {
      @extend .list-item;
    }
  }

}

.media-object__img {

}


// Variations

// Hero
.media-object--hero {
  flex-direction: flex-end;
  justify-content: space-between;
  position: relative;


  @include big-tablet {
    flex-direction: column;
  }

  .media-object__img {
    width: 40%;
    background-size: cover;
    background-position: center;
    position: absolute;
    height: 110%;
    right: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    @include big-tablet {
      display: none;
    }

    img {
      display: none;
    }
  }

  .media-object__text {
    background: $white;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    width:60%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    @include paddingX($space-md * 4);
    @include paddingY($space-lg);

    @include big-tablet {
      border-top-left-radius: 0;
      border-bottom-right-radius: $border-radius;
      background: none;
      padding: 0;
    }

    p {
      margin-bottom: $space-lg;
    }
  }

  .media-object__img,
  .media-object__text {

    @include big-tablet {
      width: 100%;
    }
  }
}

// Contact
.media-object--contact {
  display: flex;
  background: transparent;
  flex-direction: row-reverse;
  justify-content: space-between;

  @include  big-mobile {
    flex-direction: column;
  }

  & > * {
    width: calc(100% / 2);

    @include big-mobile {
      width: 100%;
    }
  }

  .media-object__img {

    @include big-mobile {
      display: none;
    }

  }

  .media-object__text {
    background: transparent;
    padding: 0;
  }
}

// Offset banner
// .media-object--offset {
//   position: relative;
//   margin-bottom: $offset-value + $space-lg * 8;

//   @include tablet {
//     margin-bottom: $space-lg * 2;
//   }


//   &.media-object--offset--odd {

//     .media-object__text {
//       position: absolute;
//       right: 0;
//       bottom: -$offset-value;

//       @include big-tablet {
//         position: static;
//       }
//     }

//   }

//   &.media-object--offset--even {
//     flex-direction: row-reverse;

//     .media-object__text {
//       position: absolute;
//       left: 0;
//       top: $offset-value;

//       .media-object__title {
//         left: 0;
//       }

//       @include big-tablet {
//         position: static;
//       }

//     }
//   }

//   .media-object__text {
//     @extend %box-shadow;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     // @include paddingX($space-lg * 4);
//     background: $color-light;
//     border-radius: $border-radius;
//     // height: 80%;
//     width: calc((100% / 2) + #{$space-lg * 2});

//     padding: $space-lg * 2;
//     @include paddingX($space-lg * 4);
//     // padding-left: $space-lg * 4;

//     @include big-desktop {
//       height: 100%;
//     }

//     @include desktop {
//       @include paddingX($space-lg * 3);
//     }

//     @include big-tablet {
//       border-top-left-radius: 0;
//       border-top-right-radius: 0;
//       padding: $space-md * 2;
//       width: 100%;
//     }

//     & > * {
//       margin-bottom: $space-md;

//       &:last-child {
//         margin-bottom: 0;
//       }
//     }

//     .btn {
//       max-width: 220px;
//     }
//   }

//   .media-object__title {
//     position: absolute;
//     top: -$offset-value - $space-lg * 2;
//     color: $color-accent;
//     @include rfs(46);
//     width: calc(100% - #{$space-md * 10});

//     & + em {
//       @include rfs(28);
//       font-weight: bold;
//     }

//     @include big-tablet {
//       position: static;
//     }
//   }

// }

// Icon list
.media-object--icon-list {
  // @include widthLimitAt(margin, 960px);
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  @include big-mobile {
    flex-wrap: wrap;
  }

  .media-object__img {
    width: 15%;
    max-width: 70px;
    margin-bottom: $space-lg;

    @include big-mobile {
      @include marginX(auto);
    }
  }

  section {
    width: 88%;

    @include big-mobile {
      width: 100%;
      text-align: center;
    }
  }
}

// test
.media-object--offset {
  @include paddingY($space-lg * 6);
  // margin-bottom: 0;
  flex-wrap: nowrap;

  @include big-tablet {
    @include paddingY(0);
  }

  .media-object__img {
    margin-top: -$space-lg * 8;

    img {
      border-radius: $border-radius;
    }

    @include big-tablet {
      margin-top: 0;
      // transform: scale(1.1);
    }
  }

  .media-object__text {
    background: $white;
    padding: $space-lg * 2;
    border-radius: $border-radius;
    width: 60%;
    position: relative;

    & > * {
      margin-bottom: $space-lg;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @include big-tablet {
      width: 100%;
      background: none;
      padding: 0;
      padding-bottom: $space-md;
      margin-bottom: $space-lg;

    }
  }

  .media-object__title {
    color: $color-accent;
    font-size: 70px;
    line-height: 1;
    position: absolute;
    top: -10vw;
    left: 0;

    @include x-big-desktop {
      font-size: 4vw;
    }

    @include big-tablet {
      position: static;
      font-size: px-to-rem(32);
    }
  }

  &:nth-child(odd) {
    @include big-tablet {
      flex-direction: column-reverse;
    }
    .media-object__img {
      position: relative;
      left: 0;
      margin-right: -$space-lg * 4;
    }

    .media-object__title {
      left: $space-lg * 6;
    }
  }

  &:nth-child(even) {
    flex-direction: row-reverse;

    @include big-tablet {
      flex-direction: column-reverse;
    }

    .media-object__img {
      position: relative;
      right: 0;
      margin-left: -$space-lg * 4;

      @include big-tablet {
        margin-left: 0;
      }
    }
  }

  &:last-child {
    padding-bottom: 0;
  }

  em {
    display: block;
    font-size: px-to-rem(28);
    font-weight: bold;
  }

  br {
    @include tablet {
      display: none;
    }
  }

  ul {
    @extend .col;
    @extend .col--2;
  }
}