/*
Layout
 */

.col {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > * {
    margin-bottom: $space-lg;
  }

  @for $i from 1 through 4 {
    &.col--#{$i} {
      @if $i == 1 {
        justify-content: center;
      }

      & > * {
        width: calc((100% / #{$i}) - #{$space-md});

        @include tablet {
          width: calc((100% / 2) - #{$space-md});
        }

        @include big-mobile {
          width: 100%;
        }
      }
    }
  }

  @include big-mobile {
    & > * {
      margin-bottom: $space-lg;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

