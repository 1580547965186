/*
List
*/

// List item: use on li element
.list-item {
  margin-bottom: $space-lg;
  position: relative;
  padding-left: $space-lg;
  color: $color-gray;

  &::before {
    position: absolute;
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-top: 2px solid $color-dark;
    border-right: 2px solid $color-dark;
    transform: rotate(45deg);
    left: 0;
    top: 3px;
  }

  &.list-item--steps {
    display: flex;
    margin-bottom: $space-lg * 4;
    padding: 0;
    flex-wrap: wrap;
    position: relative;

    @include tablet {
      @include marginX($space-md);
      margin-bottom: $space-lg * 2;
    }

    &::before {
      content: '';
      display: block;
      background-repeat: no-repeat;
      border: none;
      width: 250px;
      height: 250px;
      position: static;
      transform: none;
      background-position: center top;
      opacity: .2;

      @include tablet {
        position: absolute;
        background-position: left;
      }
    }

    &:nth-child(1)::before { background-image: url('/img/1.svg') }
    &:nth-child(2)::before { background-image: url('/img/2.svg') }
    &:nth-child(3)::before { background-image: url('/img/3.svg') }

    // Odd case
    &:nth-child(odd) {
      flex-direction: row-reverse;
      justify-content: flex-end;

      .list-item--steps__title,
      p {
        @include contentEdge(padding, left);
      }

      .btn {
        @include contentEdge(margin, left);
      }

      @include tablet {
        .list-item--steps__title,
        p {
          padding-left: 0;
        }

        .btn {
          margin-left: 0;
        }
      }
    }

    // Even case
    &:nth-child(even) {
      justify-content: flex-end;

      .list-item--steps__title,
      p {
        @include contentEdge(padding, right);
      }

      .btn {
        @include contentEdge(margin, right);
      }

      @include tablet {
        .list-item--steps__title,
        p {
          padding-right: 0;
        }

        .btn {
          margin-right: 0;
        }
      }
    }

    section {
      width: calc(100% / 2);

      @include tablet {
        width: 100%;
      }

      & > * {
        margin-bottom: $space-lg;
      }
    }

    .list-item--steps__title {
      border-bottom: 2px solid $color-accent;
      padding-bottom: $space-md;
    }
  }
}

