/*
Helper Function
*/

// Convert px value to rem value
@function px-to-rem($target) {
  @return ($target / $base-font-size-value) + rem;
}

@function rem-to-px($target) {
  @return ($target * $base-font-size-value) + px;
}

@function contentEdge() {
  $max-width: $content-width;
  @return calc((100% - #{$max-width}) / 2);
}