// Mixin

@mixin contentWidthReset {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
}

$max-width: $content-width;

// Use for horizontal container limit
@mixin contentWidth ($method) {
    #{$method}-left: 5vw;
    #{$method}-right: 5vw;

    @include big-tablet {
        #{$method}-left: 5vw;
        #{$method}-right: 5vw;
    }

    @include big-mobile {
        #{$method}-left: $space-md;
        #{$method}-right: $space-md;
    }

    @media (min-width: $x-big-desktop) {
        #{$method}-left: calc((100% - #{$max-width}) / 2);
        #{$method}-right: calc((100% - #{$max-width}) / 2);
    }

}

// Use for horizontal container limit but only for one side
@mixin contentEdge($method, $direction) {
    #{$method}-#{$direction}: 10vw;

    @include big-tablet {
      #{$method}-#{$direction}: 5vw;
    }

    @include big-mobile {
      #{$method}-#{$direction}: $space-md;
    }

    @media (min-width: $x-big-desktop) {
      #{$method}-#{$direction}: calc(100% - #{$max-width} / 2);
    }
}

@mixin widthLimitAt ($method, $width-value) {
    #{$method}-left: calc((100% - #{$width-value}) / 2);
    #{$method}-right: calc((100% - #{$width-value}) / 2);

    @media (max-width: ($desktop + (($base-font-size-value * 2) * 1.16) * 2)) {
        #{$method}-left: $space-md;
        #{$method}-right: $space-md;
    }
}

@mixin fixedAspectRatio ($height, $width) {
    position: relative;

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding-top: ($height / $width) * 100%;
    }
}

@mixin resetAspectRatio {
    &:after {
        padding: 0;
        display: none;
    }
}

@mixin transition {
    transition: .3s ease-in-out;
}

@mixin paddingX ($size) {
    padding-left: $size;
    padding-right: $size;
}

@mixin paddingY ($size) {
    padding-top: $size;
    padding-bottom: $size;
}

@mixin marginX ($size) {
    margin-left: $size;
    margin-right: $size;
}

@mixin marginY ($size) {
    margin-top: $size;
    margin-bottom: $size;
}
