
%box-shadow-bl {

  &::after {
    content: '';
    display: block;
    background: $color-accent;
    width: 100%;
    height: 100%;
    bottom: -26px;
    left: -26px;
    z-index: -1;
    position: absolute;
  }
}

%box-shadow-tl {

  &::after {
    content: '';
    display: block;
    background: $color-accent;
    width: 100%;
    height: 100%;
    top: -26px;
    left: -26px;
    z-index: -1;
    position: absolute;
  }
}

%box-shadow {
  -webkit-box-shadow: 2px 2px 12px 0px rgba($color-dark, 0.1);
  -moz-box-shadow: 2px 2px 12px 0px rgba($color-dark, 0.1);
  box-shadow: 2px 2px 12px 0px rgba($color-dark, 0.1);
}

%hover-transition {
  // Add !important in case end user using Grammerly in browser that inject sytle to DOM
  transition: .3s ease-in-out !important;
}

%heading-decorative {
  &::after {
    content: '';
    display: block;
    width: 73px;
    height: 5px;
    background: $color-accent;
    margin-top: $space-md;
  }
}

%disable-input-appearance {
   // Disable default styling on ff
   -moz-appearance: none;
   // Disable ugly ass outline on firefox
   &:-moz-focusring { color: transparent; text-shadow: 0 0 0 #000; }
   // Disable default styling on webkit browsers
   -webkit-appearance: none;
   // Disable default arrow on IE 11+
   &::-ms-expand { display: none; }
}

%cta-arrow {
  position: relative;
  &::before {
    content: '';
    display: block;
    width: 12px;
    height: 2px;
    background: $white;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: $space-md;
  }

  &::after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-top: 2px solid $white;
    border-right: 2px solid $white;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
  }
}

%visual-blob-left {
  position: relative;
  &::before {
    position: absolute;
    content: '';
    width: 8vw;
    height: 8vw;
    background: $color-accent;
    display: block;
    top: -2vw;
    left: 0;
    border-right: 2vw solid $color-light;
    border-bottom: 2vw solid $color-light;
  }
}

%visual-blob-right {
  position: relative;
  &::before {
    position: absolute;
    content: '';
    width: 8vw;
    height: 8vw;
    background: $color-accent;
    display: block;
    top: -2vw;
    right: 0;
    border-left: 2vw solid $color-light;
    border-bottom: 2vw solid $color-light;
  }
}

%input-style {
    input[type="text"],
    input[type="date"],
    input[type="number"],
    input[type="email"],
    textarea {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none;
      @extend %box-shadow;
      border-radius: $border-radius;
      background: transparent;
      padding: $space-sm $space-md;
      display: block;
      color: $color-dark;
      margin-bottom: $space-lg;


      &::placeholder {
        color: $color-gray;
        opacity: .5;
      }

      @extend %body-text;
      @extend %hover-transition;
      @include rfs(20);

      &:focus {
        outline: none;
      }
    }

    textarea {
      color: $color-dark;
      width: 100%;
      resize: vertical;
      padding:$space-md;

      &::placeholder {
        color: $color-gray;
        opacity: .5;
      }
    }
}
