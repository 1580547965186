.wizard {
  max-width: 700px;
  @include marginX(auto);
  background-color: $white;
}

.wizard__step {
  border-radius: $border-radius;
  padding-top: $space-lg;
  line-height: 1.5;
  @extend %body-text;
  @include rfs(22);
  color: $color-dark;

  & > * {
    margin-bottom: $space-lg;
    max-width: 600px;
    @include marginX(auto);

    @include big-mobile {
      max-width: initial;
    }
  }

  .foote-note {
    font-size: .9rem;
    max-width: 500px;
  }



  label {
    @include rfs(16);
  }

  input {
    width: 100%;
  }

  strong {
    display: inline;
    font-weight: 900;
  }

  .btn {
    max-width: 220px;
    @include marginX(auto);

    @include big-mobile {
      margin-bottom: $space-md;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.wizard__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $space-md;
  color: $color-accent;

  h2 {
    margin-bottom: 0;
    color: $color-accent;
  }
}

.progress-bar {
  width: 100%;
  background-color: $color-light-gray;
  display: block;
  width: 100%;
  height: 4px;
  border-radius: 10px;
  overflow: hidden;

  .progress {
    border-radius: 10px;
    display: block;
    width: 100%;
    height: 4px;
    background-color: $color-accent;
    transition: .3s ease-in-out;
  }
}

.co-applicant {
  color: $color-cta;
}

.co-applicant-btn {
  background: $color-cta !important;
  border: 2px solid $color-cta !important;

  &:hover {
    background: darken($color-cta, 10) !important;
  }
}