/*
Media Query
*/

// Common Breakpoints
$mobile: 480px;
$big-mobile: 540px;
$tablet: 768px;
$big-tablet: 960px;
$desktop: 1024px;
$big-desktop: 1240px;
$x-big-desktop: 1650px;

// Component Breakpoints
