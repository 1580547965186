/*
Typography
*/

// Set Based Font Size
$base-font-size-value: 16;
$base-font-size: $base-font-size-value + px;

// Default font
$default-font: 'Overpass', sans-serif;

:root {
  font-size: $base-font-size;
  font-family: $default-font;
}


// Title Text
$font-family-title: 'Chivo', sans-serif;

// Body Text
$font-family-body: $default-font;


// UI Text
$font-family-ui: $font-family-body;

// Heading Rules
@for $i from 1 through 6 {
  h#{$i}, .h#{$i} {
    font-family: $font-family-title;
  }

}

// extend module
%heading-text {
  font-family: $font-family-title;
  color: $color-dark;
  line-height: 1.2;
  font-weight: 900;
  margin-bottom: $space-md;
}

%body-text {
  font-family: $font-family-body;
  color: $color-gray;
  line-height: px-to-rem(18);
}

// UI
%ui {
  font-family: $font-family-ui;
  @include rfs(16);
  text-decoration: none;
  letter-spacing: px-to-rem(2);

  @include big-mobile {
    @include rfs(14);
  }
}

/*
Note: all h tag calculated based on h2 and h3 form design file,
could change over the design iteration
design
  h2 => 46px
  h3 => 40px
*/
h1, %h1, .h1 {
  @extend %heading-text;
  @include rfs(44);
}
h2, %h2, .h2 {
  @extend %heading-text;
  @include rfs(34);

  // @include big-mobile {
  //   @include rfs(20);
  // }
}
h3, %h3, .h3 {
  @extend %heading-text;
  @include rfs(25);
}
h4, %h4, .h4 {
  @extend %heading-text;
  @include rfs(16);
}
h5, %h5, .h5 {
  @extend %heading-text;
  @include rfs(16);
}
h6, %h6, .h6 {
  @extend %heading-text;
  @include rfs(16);
}

// Body rules
p {
  @extend %body-text;
  @include rfs(18);
  line-height: 1.5;

  @include big-mobile {
    @include rfs(16);
  }
}

a {
  text-decoration: none;
  color: $color-accent;

  &:hover,
  &:active {
    color: darken($color-accent, 10%);
  }
}

