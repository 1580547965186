.form {
  // @extend %ui;
  display: flex;
  flex-direction: column;
  background-color: $white;
  padding: $space-lg;

  border-radius: $border-radius;

  & > * {
    width: 100%;
  }

  &.form--basic {

  @extend %input-style;

    .select-wrapper {
      border: 1px solid $color-accent;
      position: relative;
      margin-bottom: $space-lg * 3;

      &:after {
          content: '';
          position: absolute;
          right: 20px;
          top: 10px;
          pointer-events: none;
          display: block;
          width: 10px;
          height: 10px;
          border-top: 1px solid #333;
          border-right: 1px solid #333;
          transform: rotate(135deg);
      }
    }

    & > * {

      @include big-mobile {
        margin-bottom: $space-md;
      }
    }
  }

  label {
    display: block;
    margin-bottom: $space-md;
    font-weight: bold;

    & > * {
      margin-bottom: $space-md;
      display: block;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}


// Custom Input Styles
// Wrapper class for custom select
.form__select-wrapper {

  position: relative;
  margin-bottom: $space-lg;
  @extend %box-shadow;
  border-radius: $border-radius;
  background: transparent;
  padding: $space-sm $space-md;
  display: block;
  color: $color-dark;
  margin-bottom: $space-lg;

  &:after {
    content: '';
    position: absolute;
    right: 20px;
    bottom: 20px;
    pointer-events: none;
    display: block;
    width: 10px;
    height: 10px;
    border-top: 3px solid $color-dark;
    border-right: 3px solid $color-dark;
    transform: rotate(135deg);

    @include big-mobile {
      width: 10px;
      height: 10px;
      right: 0;
      top: 20px;
    }
  }

  select {

    @extend %disable-input-appearance;

    border: none;
    border-radius: 0;
    background: none;
    padding-left: 0;
    font-size: 1.25rem;
    text-align: center;
    width: 100%;
    color: $color-dark;
    cursor: pointer;

    &:focus {
      outline: none;
    }

  }

}

// Wrapper class for custom checkbox
.form__checkbox-wrapper {
  // border-bottom: 3px solid $color-accent;
  margin-bottom: $space-lg;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .checkbox-wrapper__input-group {
    position: relative;
    bottom: 10px;
    display: flex;
  }

  .checkbox-item__checkbox {
    display: flex;
    align-items: center;
    margin-left: $space-md;
    cursor: pointer;

    &::before {
      content: '';
      width: 20px;
      height: 20px;
      display: block;
      border: 3px solid $color-accent;
      margin-right: $space-md;
      border-radius: 2px;
    }
  }

  input[type="checkbox"] {
    @extend %disable-input-appearance;
    display: none;

    &:checked {
      & + .checkbox-item__checkbox {
        position: relative;
        &::before {
          background: $color-accent;
        }
        &::after {
          content: '';
          position: absolute;
          top: 7px;
          left: 6px;
          width: 12px;
          height: 6px;
          display: block;
          border-left: 3px solid $white;
          border-bottom: 3px solid $white;
          transform: rotate(-45deg);
          border-radius: 2px;
        }
      }
    }
  }
}


.file-upload {
  @extend input[type="text"];
  display: flex !important;
  justify-content: space-between;
  align-items: center;

  .file-upload__btn--disable {
    opacity: .5;
    pointer-events: none;

  }
}

.form .btn {
  max-width: 220px;
}

// Email Subscription box
.form--email-subscription {
  max-width: 600px;
  margin: 0 auto;
  @extend %input-style;

  & > * {
    display: flex;
    flex-direction: row;
    width: 100%;

    @include big-mobile {
      flex-direction: column;
    }

  }


  input[type="email"] {
    border: 1px solid $black;
    margin-bottom: 0;
    width: 100%;

    @include big-mobile {
      margin-bottom: $space-md;
    }
  }

  .btn.btn--cta {
    text-align: center;
    display: block;
    min-width: initial;
    margin-bottom: 0;
    max-width: 100px;

    @include big-mobile {
      max-width: initial;
      margin: 0;
      width: 100%;
    }
  }
}
