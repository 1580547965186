/*
Common Variable
*/

// Width
$content-width: $big-desktop;

// Spacing
$space-sm: .5rem;
$space-md: 1rem;
$space-lg: 1.5rem;

// Common Color
$black: #000;
$white: #fff;

// Style Guide
$color-accent: #3B77A2;
$color-dark: #081E33;
$color-gray: #6A6A6A;
$color-light-gray: #ededed;
$color-light: #fff;
$color-cta: #96C322;
$border-radius: 8px;