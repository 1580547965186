// Gallery
.gallery-grid {
  display: grid;
  grid-gap: $space-md;
  height: 1024px;
  // grid-template-columns: repeat(9fr);
  // grid-template-rows: repeat(4fr);
  // grid-template-areas:
  //   "_1  _1 _1 _2  _2 _2  _4 _4  _4"
  //   "_1  _1 _1 _3  _3 _3  _4 _4  _4"
  //   "_5  _5 _5 _5  _6 _6  _6 _6  _6"
  //   "_5  _5 _5 _5  _6 _6  _6 _6  _6";

  @include x-big-desktop {
    height: 800px;
  }

  @include x-big-desktop {
    height: 600px;
  }

  @include big-mobile {
    display: flex;
    flex-direction: column;
    height: initial;
  }

  li {
    margin: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    position: relative;
    cursor: pointer;



    .gallery-grid__slide {
      display: block;
      width: 100%;
      height: 100%;
      overflow: hidden;
      background-size: cover;
      background-position: center;
      position: absolute;
      top: 0;
      left: 0;

      // transition
      transition: .3s ease-in-out;

    }


    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      background: $color-dark;
      // transition
      opacity: .2;
      transition: .3s ease-in-out;
      z-index: 3;
      pointer-events: none;
    }

    .label {
      content: attr(data-label);
      position: absolute;
      right: 0;
      bottom: 0;
      font-size: .8rem;
      padding: .5rem 1rem;
      display: block;
      color: $color-light-gray;
      background: rgba($color-dark, 0.5);
      z-index: 4;
    }

    &:hover {
      .gallery-grid__slide {
        transform: scale(1.1);
      }

    }
    &::before {
      opacity: 0;
    }


    @include big-mobile {
      margin-bottom: $space-md;
      padding-top: 50%;
    }



    @for $i from 1 through 10 {
      &:nth-child(#{$i})  {
        grid-area: _#{$i} ;
      }
    }

  }
}

.gallery-grid--set-1 {
  grid-template-areas:
    "_1 _1 _1 _2 _2 _2 _4 _4 _4"
    "_1 _1 _1 _3 _3 _3 _4 _4 _4"
    "_5 _5 _5 _5 _6 _6 _6 _6 _6"
    "_5 _5 _5 _5 _6 _6 _6 _6 _6";

    li {
      @include big-mobile {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(4) {
          display: none;
        }
      }
    }
}

.gallery-grid--set-2 {
  grid-template-areas:
    "_1 _1 _2 _3"
    "_1 _1 _2 _3"
    "_4 _4 _5 _5"
    "_4 _4 _5 _5";

    li {
      @include big-mobile {
        &:nth-child(2),
        &:nth-child(3) {
          display: none;
        }
      }
    }
}

.gallery-grid--set-3 {
  grid-template-areas:
    "_1 _1 _1 _2 _2 _2"
    "_3 _3 _4 _4 _5 _5";

    li {
      @include big-mobile {
        &:nth-child(4),
        &:nth-child(5) {
          display: none;
        }
      }
    }
}

.gallery-grid--set-4 {
  grid-template-areas:
    "_1 _1 _1 _1 _2 _2 _2 _3 _3"
    "_4 _4 _4 _5 _5 _5 _6 _6 _6";

    li {
      @include big-mobile {
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(5) {
          display: none;
        }
      }
    }
}