
/*
Footer
*/

.site-footer {
  @include contentWidth(padding);
  background: $color-dark;
  color: $color-light;
  @include paddingY($space-lg * 2);

  & > * {
    margin-bottom: $space-lg * 2;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: $white;

    &:hover {
      color: $color-accent;
    }
  }

  @include big-mobile {
    br {
      display: initial;
    }
  }
}

// Partner
.partner {
  opacity: .5;

  h2 {
    @extend %h4;
    color: $white;
    text-align: center;
    margin-bottom: $space-lg;
  }

  ul {
    display: flex;
    justify-content: center;

    @include big-tablet {
      flex-direction: column;
    }

    & > * {
      width: calc(100% / 3);
      max-width: 200px;
      @include marginX($space-lg * 2);
      display: flex;
      align-items: center;
      justify-content: center;

      &:nth-child(3),
      &:nth-child(4) {
        figure {
          height: 80px;
        }
      }


      @include big-tablet {
        width: 100%;
        margin: $space-md auto;
      }
    }

    figure {
      height: 40px;

      img {
        height: 100%;
      }
    }
  }
}

.footer-nav {

  & > ul {
    display: flex;
    justify-content: space-between;


    @include big-tablet {
      flex-direction: column;
    }

    & > * {
      width: calc((100% / 3) - #{$space-lg});
      max-width: 250px;
      // border: 1px solid red;

      @include big-tablet {
        width: 100%;
        max-width: initial;
        text-align: center;
        margin-bottom: $space-lg * 2;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .divider {
    width: 4px;
    height: 80px;
    background: $color-accent;
    display: block;

    @include big-tablet {
      height: 4px;
      width: 80px;
      @include marginX(auto);
    }
  }
}

.footer-nav__contact {

  & > * {
    margin-bottom: $space-md;
    display: block;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h3 {
    color: $white;
    font-size: px-to-rem(20);
  }
  address {
    line-height: 1.6;
  }



}

.footer-nav__main {
  ul {
    flex-direction: column;

    & > * {
      margin-bottom: $space-md;
      width: 100%;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.site-footer__logo {

  figure {
    max-width: 150px;
    margin-bottom: $space-md;

    @include big-tablet {
      @include marginX(auto);
    }
  }
}

.legal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $color-light;
  color: $color-dark;
  margin-bottom: 0;
  @include contentWidth(padding);
  @include paddingY($space-md);

  @include big-mobile {
    flex-wrap: wrap;
    & > * {
      width: 100%;
      text-align: center;
      margin-bottom: $space-sm;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}