@mixin mobile {
  @media (max-width: $mobile) {
    @content;
  }
}

@mixin big-mobile {
  @media (max-width: $big-mobile) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: $tablet) {
    @content;
  }
}

@mixin big-tablet {
  @media (max-width: $big-tablet) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: $desktop) {
    @content;
  }
}

@mixin big-desktop {
  @media (max-width: $big-desktop) {
    @content;
  }
}


@mixin x-big-desktop {
  @media (max-width: $x-big-desktop) {
    @content;
  }
}
