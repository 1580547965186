/*
Card
*/

.card {
  @extend %box-shadow;
  background: $color-light;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
  list-style: none;
  border-radius: $border-radius;

  &:hover {
    .card__title {
      color: $color-accent;
    }
  }

  &.badge {
    position: relative;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: attr(data-label);
      display: block;
      color: $color-light;
      background: $color-dark;
      padding: $space-sm $space-md;
      min-width: 200px;
      text-align: center;
      z-index: 10;
      transform: rotate(-45deg) translate(-60px, -10px);
    }

    &.badge--sold::before { background: red; }
    &.badge--construction::before { background: $color-dark; }
  }

  .card__title {
    font-weight: normal;
    text-transform: none;
    margin-bottom: $space-lg;
    font-weight: bold;
    @extend %h4;
    @extend %hover-transition;

    em {
      font-weight: normal;
      display: block;
    }
  }
  .card__text {
    padding: $space-md;
  }

  .card__icon-list {
    display: flex;
    color: $color-accent;
    align-items: flex-end;

    img {
      width: 30px;
    }

    li {
      margin-right: $space-md;
      display: flex;
      align-items: flex-end;

      & > * {
        margin-right: $space-sm;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.card__img--placeholder {
  position: relative;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    content: '';
    width: 100%;
    height: 100%;
    background-color: $color-accent;
    opacity: .6;
  }
}

// Card contact
.card-contact {
  border-radius: $border-radius;
  // background: $white;
  padding: $space-lg;

  & > * {
    display: block;
    margin-bottom: $space-lg;
  }

  address {
    @extend %body-text;
    line-height: 1.5;
  }

  .btn {
    max-width: 220px;
  }
}


// Override Gmap InfoWindow to fit listing card
.gm-style-iw {
  overflow: hidden !important;
  height: 335px !important;
  max-height: initial !important;
  padding-left: 18px !important;
}

.gm-style-iw-d {
  max-height: 335px !important;
}