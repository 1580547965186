.breadcrumb {
  @include contentWidth(padding);
  @include paddingY($space-lg);
  display:  flex;

  & > * {

    &::first-letter {
      text-transform: uppercase;
    }

    &:after {
      content: '>';
      color: $color-accent;
      @include marginX($space-sm);
    }

    &:last-child {
      &:after {
        content: ''
      }
    }
  }

  & + * {
    padding-top: 0;
  }
}