/*
Button
*/

.btn {
  @extend %ui;
  @extend %hover-transition;
  border-radius: $border-radius;
  border: 2px solid $color-dark;
  background: $color-dark;
  color: $color-light;
  padding: $space-sm $space-md;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  @include rfs(16);
  letter-spacing: initial;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  white-space: nowrap;

  // text-transform: lowercase;
  // &::first-letter {
  //   text-transform: uppercase;
  // }

  &:hover,
  &:active {
    background: darken($color-dark, 10%);
    color: $color-light;
  }

  @include big-mobile {
    @include rfs(12);
    padding: px-to-rem(12);
  }



  &.btn--primary {
    border: none;
    border: 2px solid $color-accent;
    background: $color-accent;
    color: $color-light;

    &:hover,
    &:active {
      border-color: darken($color-accent, 10%);
      background: darken($color-accent, 10%);
      color: $color-light;
    }
  }

  &.btn--secondary {
    border: 2px solid $color-accent;
    background: none;
    color: $color-accent;

    &:hover,
    &:active {
      border-color: $color-accent;
      background: $color-accent;
      color: $color-light;
    }
  }

  &.btn--cta {
    background: none;
    border: 2px solid $color-cta;
    background: $color-cta;
    color: $white;
    display: flex;
    align-items: center;

    &:hover {
      color: $white;
      background: darken($color-cta, 6);
    }
  }

}

a.btn--light {
  background: $color-light;
  color: $color-dark !important;
  @extend %ui;
  text-transform: uppercase;

  &:hover {
    color: $color-light !important;
  }
}